import * as React from 'react';
import Votes from './Pages/Votes.jsx';
import Ideas from './Pages/Ideas.jsx';
import Results from './Pages/Results.jsx';

export default function MainMenu({ currentPage, setCurrentPage, token }) {
  return(
    <div style={{height:"900", backgroundColor:"#212529", paddingBottom:"20%"}}>
        {
            currentPage == 0 && (
                <Votes token={token} />
            )
        }
        {
            currentPage == 1 && (
                <Ideas />
            )
        }
        {
            currentPage == 2 && (
                <Results />
            )
        }
      <div style={{fontFamily: "Nunito", fontWeight:"700", color:"#edeef0", width: "calc(100% - 110px)", height: "50%", margin: "50px 55px"}}>
        &copy; <a href="https://muzilok.ru">MUZILOK</a> 2023 - {new Date().getFullYear()}.
      </div>
    </div>
  )
}