import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

export default function Ideas() {

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                padding: 8
            }}>
                <p style={{
                    color: "#fff",
                    fontFamily: "Nunito",
                }}>Свои идеи предлагайте в поддержке в</p>

            </div>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                padding: 8
            }}>
                <a  style={{
                    color: "lightblue",
                    fontFamily: "Nunito",
                }} align={"center"} href={"https://t.me/muzilokhitday"}>чат-поддержке
                    Телеграм</a>

            </div>
        </>
    )

    return (
        <div>
            <div
                style={{
                    width: "calc(100% - 110px)",
                    height: "50%",
                    margin: "50px 55px 20px 55px",
                }}
            >
                <TextField
                    placeholder="Напишите название своей идеи"
                    sx={{
                        "*::placeholder": {
                            color: "#696969",
                            fontFamily: "Nunito",
                            fontWeight: 800,
                            fontSize: "18px"
                        },
                        backgroundColor: "#edeef0",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                        boxShadow: "none",
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: "20px",
                        "& fieldset": {border: 'none'},
                    }}
                />
            </div>
            <div
                style={{
                    width: "calc(100% - 110px)",
                    height: "50%",
                    margin: "10px 55px",
                }}
            >
                <TextField
                    placeholder="Напишите подробное описание своей идеи"
                    sx={{
                        "*::placeholder": {
                            color: "#696969",
                            fontFamily: "Nunito",
                            fontWeight: 800,
                            fontSize: "18px"
                        },
                        backgroundColor: "#edeef0",
                        borderRadius: "15px",
                        width: "100%",
                        height: "150px",
                        boxShadow: "none",
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: "20px",
                        "& fieldset": {border: 'none'},
                    }}
                />
            </div>
            <div style={{
                width: "calc(100% - 110px)",
                height: "50%",
                margin: "10px 55px"
            }}>
                <Button variant="contained" sx={{
                    backgroundColor: "#8257ff",
                    borderRadius: "15px",
                    width: "100%",
                    height: "40px",
                    marginTop: "20px"
                }}>Отправить</Button>
            </div>
        </div>
    );
}
