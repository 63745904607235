import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconOne from "./placeIcons/1.jsx";
import IconTwo from "./placeIcons/2.jsx";
import IconThree from "./placeIcons/3.jsx";

export default function Results() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
      <div style={{
        display: 'flex',
        justifyContent: "center",
        padding: 8
      }}>
        <h4
        style={{
          color: "#fff",
          fontFamily: "Nunito",
          fontSize: 18
        }}
        >Идет первое голосование, итоги появятся после того, как голосование закончится</h4>
      </div>
  )

  return (
    <div
      style={{
        width: "calc(100% - 110px)",
        height: "50%",
        margin: "50px 55px",
        padding: "2%",
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          backgroundColor: "#212529",
          boxShadow: "none",
          color: "#edeef0",
        }}
      >
        <AccordionSummary
          expandIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ADADAD"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <IconOne />
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 700 }}
              >
                1 место
              </Typography>
            </Grid>
            <Grid item xs={3} md={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 800 }}
              >
                COFFICCC
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography
                variant="7"
                sx={{ fontFamily: "Nunito", fontWeight: 500 }}
              >
                Идея: Слова в песне
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontFamily: "Nunito", fontWeight: "700", fontSize: "16px" }}
          >
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          backgroundColor: "#212529",
          boxShadow: "none",
          color: "#edeef0",
        }}
      >
        <AccordionSummary
          expandIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ADADAD"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          }
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <IconTwo />
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 700 }}
              >
                2 место
              </Typography>
            </Grid>
            <Grid item xs={3} md={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 900 }}
              >
                VEALOK
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography
                variant="7"
                sx={{ fontFamily: "Nunito", fontWeight: 500 }}
              >
                Идея: Слова в песне
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontFamily: "Nunito", fontWeight: "700", fontSize: "16px" }}
          >
            Donec placerat, lectus sed mattis semper, neque lectus feugiat
            lectus, varius pulvinar diam eros in elit. Pellentesque convallis
            laoreet laoreet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        sx={{
          backgroundColor: "#212529",
          boxShadow: "none",
          color: "#edeef0",
        }}
      >
        <AccordionSummary
          expandIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ADADAD"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <IconThree />
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 700 }}
              >
                3 место
              </Typography>
            </Grid>
            <Grid item xs={3} md={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 800 }}
              >
                TIPOK
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography
                variant="7"
                sx={{ fontFamily: "Nunito", fontWeight: 500 }}
              >
                Идея: Слова в песне
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontFamily: "Nunito", fontWeight: "700", fontSize: "16px" }}
          >
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        sx={{
          backgroundColor: "#212529",
          boxShadow: "none",
          color: "#edeef0"
        }}
      >
        <AccordionSummary
          expandIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ADADAD"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{height:"100px", width:"100px"}}></div>
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 700 }}
              >
                4 место
              </Typography>
            </Grid>
            <Grid item xs={3} md={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 800 }}
              >
                VLADUSHA
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography
                variant="7"
                sx={{ fontFamily: "Nunito", fontWeight: 500 }}
              >
                Идея: Слова в песне
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontFamily: "Nunito", fontWeight: "700", fontSize: "16px" }}
          >
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        sx={{
          backgroundColor: "#212529",
          boxShadow: "none",
          color: "#edeef0",
        }}
      >
        <AccordionSummary
          expandIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ADADAD"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{height:"100px", width:"100px"}}></div>
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 700 }}
              >
                5 место
              </Typography>
            </Grid>
            <Grid item xs={3} md={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontFamily: "Nunito", fontWeight: 800 }}
              >
                ELENKA
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography
                variant="7"
                sx={{ fontFamily: "Nunito", fontWeight: 500 }}
              >
                Идея: Слова в песне
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontFamily: "Nunito", fontWeight: "700", fontSize: "16px" }}
          >
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
