import React from 'react';
import { Container, Typography, Box } from '@mui/material';

export default function BlockedPage() {
    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '20vh' }}>
            <Box sx={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '8px' }}>
                <Typography variant="h4" sx={{ fontFamily: 'Nunito', fontWeight: '800', marginBottom: '1rem', color: '#212529' }}>
                    Доступ запрещен
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: 'gray' }}>
                    Страница доступна только для пользователей MUZILOK и только в Телеграм боте <a href={'https://t.me/muzilokbot'}>@muzilokbot</a>
                    <br />
                    Если вы явлетесь пользователем MUZILOK. <br />Скорее всего, у вас не привязан MUZILOK аккаунт к вашему Телеграм. Привяжите Телеграм к MUZILOK и голосуйте за любимые функции!
                </Typography>
            </Box>
        </Container>
    );
}
