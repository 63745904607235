import React from "react";
import { AppBar, Box, Toolbar, Typography, Tabs, Tab } from "@mui/material";

export default function Header({ currentPage, setCurrentPage }) {
    const handleTabChange = (event, newValue) => {
        setCurrentPage(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1, marginBottom: "20px" }}>
            <AppBar
                position="static"
                sx={{ backgroundColor: "#212529", boxShadow: "none", width: "100%" }}
            >
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            fontWeight: "800",
                            fontFamily: "Nunito",
                            display: { xs: "block", sm: "block" },
                            color: "#8257ff",
                            fontSize: "30px",
                            marginBottom: "20px",
                            padding: "20px",
                        }}
                    >
                        MUZILOK
                    </Typography>
                </Toolbar>
                <Tabs
                    value={currentPage}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    centered
                    textColor="inherit"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#8257ff",
                        },
                    }}
                >
                    <Tab
                        label="Голосование"
                        sx={{
                            color: currentPage === 0 ? "#8257ff" : "white",
                            fontFamily: "Nunito",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    />
                    <Tab
                        label="Идеи"
                        sx={{
                            color: currentPage === 1 ? "#8257ff" : "white",
                            fontFamily: "Nunito",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    />
                    <Tab
                        label="Итоги"
                        sx={{
                            color: currentPage === 2 ? "#8257ff" : "white",
                            fontFamily: "Nunito",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    />
                </Tabs>
            </AppBar>
        </Box>
    );
}
