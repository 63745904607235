import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    LinearProgress,
    Radio,
    Container,
    Grid,
    Box,
    Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { linearProgressClasses } from '@mui/material/LinearProgress';
import axios from 'axios';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#8257ff' : '#8257ff',
    },
}));

export default function Votes({ token }) {
    const [expanded, setExpanded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [examples, setExamples] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoriesResponse = await axios.get(`https://api.muzilok.ru/get_voice_backstage_categories?_token=${token}`);
                const examplesResponse = await axios.get(`https://api.muzilok.ru/get_voice_backstage_examples?_token=${token}`);

                const categoriesData = categoriesResponse.data;
                const examplesData = examplesResponse.data;

                setCategories(categoriesData);
                setExamples(examplesData);

                const initialSelectedValues = {};
                categoriesData.forEach(category => {
                    if (category.example !== null) {
                        initialSelectedValues[category.id] = category.example;
                    }
                });
                setSelectedValues(initialSelectedValues);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeRadio = (categoryId) => (event) => {
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [categoryId]: event.target.value
        }));
    };

    const handleVote = (categoryId) => {
        const selectedExampleId = selectedValues[categoryId];
        if (selectedExampleId) {
            axios.post(`https://api.muzilok.ru/voiceCreate?_token=${token}&id=${selectedExampleId}`)
                .then(response => {
                    setCategories(prevCategories =>
                        prevCategories.map(category =>
                            category.id === categoryId ? { ...category, voited: 1, example: selectedExampleId } : category
                        )
                    );
                    setExamples(prevExamples =>
                        prevExamples.map(example =>
                            Number(example.id) === Number(selectedExampleId) ? { ...example, voices: example.voices + 1 } : example
                        )
                    );
                    setExpanded(false);
                })
                .catch(error => {
                    console.error('Error voting:', error);
                });
        }
    };

    const handleCancelVote = (categoryId) => {
        const selectedExampleId = selectedValues[categoryId];
        if (selectedExampleId) {
            axios.post(`https://api.muzilok.ru/voiceCancel?_token=${token}&id=${selectedExampleId}`)
                .then(response => {
                    setCategories(prevCategories =>
                        prevCategories.map(category =>
                            category.id === categoryId ? { ...category, voited: 0, example: null } : category
                        )
                    );
                    setExamples(prevExamples =>
                        prevExamples.map(example => {
                                return Number(example.id) == Number(selectedExampleId) ? { ...example, voices: example.voices - 1 } : example
                            }
                        )
                    );
                    setSelectedValues(prevValues => {
                        const newValues = { ...prevValues };
                        delete newValues[categoryId];
                        return newValues;
                    });
                    setExpanded(false);
                })
                .catch(error => {
                    console.error('Error cancelling vote:', error);
                });
        }
    };

    const getExamplesByCategory = (categoryId) => {
        return examples.filter(example => example.category === categoryId);
    };

    const calculatePercentage = (exampleVoices, categoryId) => {
        const totalVoices = examples
            .filter(example => example.category === categoryId)
            .reduce((acc, curr) => acc + curr.voices, 0);
        return totalVoices ? Math.round((exampleVoices / totalVoices) * 100) : 0;
    };

    return (
        <Container>
            <Grid container spacing={4}>
                {categories.map((category) => (
                    <Grid item xs={12} md={6} key={category.id}>
                        <Card
                            sx={{
                                height: "100%",
                                padding: "2%",
                                borderRadius: "25px"
                            }}
                        >
                            <CardContent>
                                <Box mb={3}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: "Nunito",
                                            fontWeight: "800",
                                            fontSize: "18px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {category.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Nunito",
                                            color: "#ADADAD",
                                            fontWeight: "800",
                                        }}
                                    >
                                        Опрос
                                    </Typography>
                                </Box>
                                {getExamplesByCategory(category.id).map((example) => (
                                    <Accordion
                                        key={example.id}
                                        expanded={expanded === example.title}
                                        onChange={handleChange(example.title)}
                                        sx={{ boxShadow: "none", border: "none" }}
                                        disabled={category.voited === 1}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`${example.title}-content`}
                                            id={`${example.title}-header`}
                                        >
                                            <Typography
                                                sx={{ width: '10%', flexShrink: 0, color: "black", fontFamily: "Nunito", fontWeight: "900", fontSize: "14px", marginLeft: -2 }}
                                            >
                                                {calculatePercentage(example.voices, category.id)}%
                                            </Typography>
                                            <Stack sx={{ flexGrow: 1, color: "#8257ff", justifyContent: "center", marginLeft: 2 }} spacing={0}>
                                                <Typography
                                                    sx={{ width: '100%', flexShrink: 0, color: "black", fontFamily: "Nunito", fontWeight: "900", fontSize: "15px" }}
                                                >
                                                    {example.title}
                                                </Typography>
                                                <BorderLinearProgress
                                                    variant="determinate"
                                                    value={calculatePercentage(example.voices, category.id)}
                                                    sx={{ width: "calc(100% - 15%)" }}
                                                />
                                            </Stack>
                                            <Radio
                                                checked={selectedValues[category.id] == example.id}
                                                onChange={handleChangeRadio(category.id)}
                                                value={example.id}
                                                name={`radio-buttons-${category.id}`}
                                                inputProps={{ 'aria-label': example.title }}
                                                sx={{ color: "#ADADAD", opacity: "0.8" }}
                                                disabled={category.voited === 1}
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography sx={{ fontFamily: "Nunito", fontWeight: 600, fontSize: "17px" }}>
                                                {example.description}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                    {category.voited === 0 ? (
                                        <Button variant="contained" color="primary" onClick={() => handleVote(category.id)}>
                                            Проголосовать
                                        </Button>
                                    ) : (
                                        <Button variant="contained" color="secondary" onClick={() => handleCancelVote(category.id)}>
                                            Отменить голос
                                        </Button>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
