// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Components/Header';
import MainMenu from './Components/MainMenu';
import BlockedPage from './Components/BlockedPage';
import axios from 'axios';

export default function App() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isAuthorized, setIsAuthorized] = useState(false);

    const TelegramWindow = window.Telegram.WebApp;

    if (typeof(TelegramWindow.initDataUnsafe.user) !== 'undefined') {
        TelegramWindow.expand();
        TelegramWindow.enableClosingConfirmation();
    }

    const [webapp, setWebApp] = useState(false);
    const [token, setToken] = useState('');
    React.useEffect(() => {
        if (typeof(TelegramWindow.initDataUnsafe.user) !== 'undefined') {
            setWebApp(true);
        }
    }, []);

    useEffect(() => {
        const userId = TelegramWindow.initDataUnsafe.user.id;
        axios.post('https://api.muzilok.ru/loginTelegram', { userid: userId })
            .then(response => {
                console.log('asd', response.data.status === 1);
                if (response.data.status === 1) {
                    setIsAuthorized(true);
                    setToken(response.data._token);
                }
            })
            .catch(error => {
                console.error('Authorization error:', error);
                setIsAuthorized(false);
            });
    }, []);

    console.log(isAuthorized)

    if (!isAuthorized || !webapp) {
        return <BlockedPage />;
    }

    return (
        <div style={{ backgroundColor: "#212529" }}>
            <Header setCurrentPage={setCurrentPage} currentPage={currentPage} />
            <MainMenu setCurrentPage={setCurrentPage} token={token} currentPage={currentPage} />
        </div>
    );
}
